  import React from "react";
import {
  AutocompleteArrayInput,
  SimpleFormIterator,
  BooleanInput,
  DateInput,
  ArrayInput,
  NumberInput,
  TextInput,
  SelectInput,
  SelectArrayInput,
  RadioButtonGroupInput
} from 'react-admin';
import Home from '@material-ui/icons/Home';
import PlayCircleFilledRounded from '@material-ui/icons/PlayCircleFilledRounded';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

export const EstablishmentForm = () => {
  const translate = useTranslate();
  const establishmentOpions = [
    {id: 'always', name: 'always'},
    {id: 'never', name: 'never'},
    {id: 'by_exam', name: 'by_exam'},
  ];
  const establishmentGradeSharing = [
    {id: 'none', name: 'none'},
    {id: 'grade_only', name: 'grade_only'},
    {id: 'grade_and_copy', name: 'grade_and_copy'},
  ];

  return (
    <Box p="1em">
      <Box display="flex">

        <Box flex={2} mr="4em">
          <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
          <TextInput source="name" label='resources.establishments.fields.name' fullWidth />
          <TextInput source="description" label='resources.establishments.fields.description' fullWidth />
          <TextInput type="email" source="email" label='resources.establishments.fields.email' fullWidth />
          <TextInput source="customSupportPhoneNumber" label='resources.establishments.fields.customSupportPhoneNumber' fullWidth />
          <TextInput source="excelHeader" label='resources.establishments.fields.excelHeader' fullWidth />
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <NumberInput step={0.1} source="gradeA" label='resources.establishments.fields.gradeA' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <NumberInput step={0.1} source="gradeB" label='resources.establishments.fields.gradeB' fullWidth />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <NumberInput step={0.1} source="gradeC" label='resources.establishments.fields.gradeC' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <NumberInput step={0.1} source="gradeD" label='resources.establishments.fields.gradeD' fullWidth />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <NumberInput step={0.1} source="gradeE" label='resources.establishments.fields.gradeE' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em" />
          </Box>
          <NumberInput step={0.1} source="coefficient" label='resources.establishments.fields.coefficient' fullWidth />
          <BooleanInput source="homeUniversity" label='resources.establishments.fields.homeUniversity' options={{ checkedIcon: <Home /> }} fullWidth />
          <NumberInput step={0.1} source="gradeMaxExam " label='resources.establishments.fields.gradeMaxExam' fullWidth />
          <TextInput source="establishmentLanguage" label='resources.establishments.fields.establishmentLanguage' fullWidth />
          <TextInput source="logo" label='resources.establishments.fields.logo' fullWidth />
          <Typography variant="h4" gutterBottom>{translate("resources.establishments.fields.period.period")}</Typography>
          <TextInput source="period.name" label='resources.establishments.fields.period.name' fullWidth />
          <TextInput source="period.description" label='resources.establishments.fields.period.description' fullWidth />
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <DateInput source="period.startDate" label='resources.establishments.fields.period.startDate' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <DateInput source="period.endDate" label='resources.establishments.fields.period.endDate' fullWidth />
            </Box>
          </Box>
          <BooleanInput source="period.current" label='resources.establishments.fields.period.current' options={{ checkedIcon: <PlayCircleFilledRounded /> }} fullWidth />
          <Typography variant="h4" gutterBottom>Ftp</Typography>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput source="establishmentFtpParams.encryptKey" label='resources.establishment-ftp-params.fields.encryptKey' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <TextInput source="establishmentFtpParams.decryptKey" label='resources.establishment-ftp-params.fields.decryptKey' fullWidth />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput source="establishmentFtpParams.ftpHost" label='resources.establishment-ftp-params.fields.ftpHost' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
              <TextInput source="establishmentFtpParams.ftpUser" label='resources.establishment-ftp-params.fields.ftpUser' fullWidth />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput source="establishmentFtpParams.ftpKey" label='resources.establishment-ftp-params.fields.ftpKey' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em">
            </Box>
          </Box>
          <Typography variant="h4" gutterBottom>Mailing</Typography>
          <BooleanInput source="disableMailing" defaultValue={false} label='resources.establishments.fields.disableMailing' />
          <SelectArrayInput source="disabledMailings" choices={[
            { id: 'new-exam', name: 'new-exam' },
            { id: 'exam-expiration-reminder', name: 'exam-expiration-reminder' },
            { id: 'results-available', name: 'results-available' },
            { id: 'student-paper-sending-confirmation', name: 'student-paper-sending-confirmation' },
            { id: 'first-copies-ready-for-grading', name: 'first-copies-ready-for-grading' },
            { id: 'welcome', name: 'welcome' },
            { id: 'new-user-school', name: 'new-user-school' },
            { id: 'default-welcome', name: 'default-welcome' },
            { id: 'desktop-version-reminder', name: 'desktop-version-reminder' },
            { id: 'missing-copies-reminder', name: 'missing-copies-reminder' },
          ]} label='resources.establishments.fields.disabledMailings' fullWidth />
        </Box>

        <Box flex={1} ml="1em">
          <Typography variant="h4" gutterBottom>{translate("resources.establishments.fields.option")}</Typography>
          <AutocompleteArrayInput source="allowedExamFields" label='resources.establishments.fields.allowedExamFields' choices={[
            { id: 'subject', name: 'Subject' },
            { id: 'category', name: 'Category' },
            { id: 'medium', name: 'Exam medium' },
            { id: 'year', name: 'Year' },
            { id: 'planned_date', name: 'Planned date' },
            { id: 'planned_hour', name: 'Planned hour' },
            { id: 'duration', name: 'Duration' },
            { id: 'anonymous_grading', name: 'Anonymous grading' },
          ]} fullWidth />
          <AutocompleteArrayInput source="allowedExamOptions" label='resources.establishments.fields.allowedExamOptions' choices={[
            { id: 'BLOCK_SCREEN', name: 'Block the student screen' },
            { id: 'SPELL_CHECKING', name: 'Spell check' },
            { id: 'COPY_PASTE', name: 'Copy and paste' },
            { id: 'SHOW_QUESTION_COUNTDOWN', name: 'Show question timer' },
            { id: 'TEXT_ALIGN', name: 'Text align' },
            { id: 'BASIC_CALCULATOR', name: 'Basic calculator' },
            { id: 'SCIENTIFIC_CALCULATOR ', name: 'Scientific calculator' },
            { id: 'ANONYMOUS_GRADING', name: 'Anonymous grading' },
            { id: 'RESET_COUNTER', name: 'Continue question numbering across parts' },
            { id: 'FONT_FAMILY', name: 'Font customization' },
            { id: 'FONT_SIZE', name: 'Font size' },
            { id: 'FONT_COLOR', name: 'Font color customization' },
            { id: 'SHOW_ANSWER_ORDER', name: 'Show answers order' },
            { id: 'SKIP_FACE_DETECTION', name: 'Skip face detection' },
            { id: 'AUDIO_PROCTORING', name: 'Audio proctoring' },
            { id: '360_ENVIRONMENT', name: '360° environment' },
            { id: 'EXAM_DRAFT', name: 'Draft feature' },
          ]} fullWidth />
          <BooleanInput source="learningGoalManaged" label='resources.establishments.fields.learningGoalManaged' />
          <BooleanInput source="ratingManagement" label='resources.establishments.fields.ratingManagement' fullWidth />
          <BooleanInput source="allowAmericanGrade" label='resources.establishments.fields.allowAmericanGrade' fullWidth />
          <BooleanInput source="allowExportCopy" label='resources.establishments.fields.allowExportCopy' fullWidth />
          <Typography variant="h6" gutterBottom>{translate("resources.establishments.fields.gradeSharing")}</Typography>
          <SelectInput source="graderGradeSharing" choices={establishmentGradeSharing} label='resources.establishments.fields.graderGradeSharing' fullWidth />
          <SelectInput source="adminGradeSharing" choices={establishmentGradeSharing} label='resources.establishments.fields.adminGradeSharing' fullWidth />
          <BooleanInput source="allowProctoring" label='resources.establishments.fields.allowProctoring' fullWidth />
          <BooleanInput source="allowProctoringLive" label='resources.establishments.fields.allowProctoringLive' fullWidth />
          <BooleanInput source="allowProctoringAudioRecord" label='resources.establishments.fields.allowProctoringAudioRecord' fullWidth />
          <BooleanInput source="allowEnvironment" label='resources.establishments.fields.allowEnvironment' fullWidth />
          <BooleanInput source="hasSession" label='resources.establishments.fields.hasSession' fullWidth />
          <BooleanInput source="durationFixed" label='resources.establishments.fields.durationFixed' fullWidth />
          <BooleanInput source="requireSafeExamBrowser" label='resources.establishments.fields.requireSafeExamBrowser' />
          <BooleanInput source="multipleGrading" label='resources.establishments.fields.multipleGrading' />
          <BooleanInput source="virusAlert" defaultValue={true} label='resources.establishments.fields.virusAlert' />
          <BooleanInput source="totalRamDetection" defaultValue={true} label='resources.establishments.fields.totalRamDetection' />
          <BooleanInput source="mandatoryTraining" defaultValue={true} parse={v => !v} format={v => !v} label='resources.establishments.fields.mandatoryTraining' />
          <BooleanInput source="webApp" defaultValue={false} label='resources.establishments.fields.webApp' />
          <BooleanInput source="remoteExam" defaultValue={false} label='resources.establishments.fields.remoteExam' />
          <NumberInput source="timeZeroSecond" label='resources.establishments.fields.timeZeroSecond' fullWidth />
          <NumberInput step={0.1} source="proctoringStorageDuration" label='resources.establishments.fields.proctoringStorageDuration' fullWidth />
          <ArrayInput source="defaultExamOptions" label='resources.establishments.fields.defaultExamOptions' fullWidth >
            <SimpleFormIterator>
              <TextInput />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="fromEmails" label='resources.establishments.fields.fromEmails' fullWidth >
            <SimpleFormIterator>
              <SelectInput source="lang" choices={[
                { id: 'en', name: 'English' },
                { id: 'fr', name: 'French' },
                { id: 'es', name: 'Spanish' },
                { id: 'de', name: 'German' },
                { id: 'it', name: 'Italian' },
                { id: 'nl', name: 'Dutch' },
              ]} label='resources.establishments.fields.lang' fullWidth />
              <TextInput source="email" label='resources.establishments.fields.email' fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
          <NumberInput source="maxStudentsPerRoom" defaultValue={9} min={1} max={24} label='resources.establishments.fields.maxStudentsPerRoom' fullWidth />
          <RadioButtonGroupInput fullWidth source="noCorrectAnswer" label='resources.establishments.fields.noCorrectAnswer' choices={establishmentOpions} />
          <RadioButtonGroupInput fullWidth source="questionsPoints" label='resources.establishments.fields.questionsPoints' choices={establishmentOpions} />
          <RadioButtonGroupInput fullWidth source="timeOptions" label='resources.establishments.fields.timeOptions' choices={establishmentOpions} />
        </Box>
      </Box>
    </Box>
  )
}
