import React from "react";
import {ChipField, ReferenceField, TextField, ReferenceArrayField, SingleFieldList} from 'react-admin';
import {
  ShowGuesser,
  FieldGuesser, ListGuesser
} from "@api-platform/admin";

const AssessmentTypeShow= props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel={true}/>
    <FieldGuesser source="doubleApproval" addLabel={true}/>
    <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name" addLabel={true}>
      <TextField source="name"/>
    </ReferenceField>
    <FieldGuesser source="isDefault" addLabel={true}/>
    <FieldGuesser source="updatedAt" addLabel={true}/>
  </ShowGuesser>
);

export default AssessmentTypeShow;
