import React from "react";
import {ReferenceField, TextField} from 'react-admin';
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";

const UserDetailShow= props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="email" addLabel={true}/>
    <FieldGuesser source="roles" addLabel={true}/>
    <FieldGuesser source="hasExtraTime" addLabel={true}/>
    <FieldGuesser source="locale" addLabel={true}/>
    <FieldGuesser source="studentNumber" addLabel={true}/>
    <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name" addLabel={true}>
      <TextField source="name"/>
    </ReferenceField>

  </ShowGuesser>
);

export default UserDetailShow;
