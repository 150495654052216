import React from "react";
import {
  DeleteWithConfirmButton,
  SearchInput,
  ShowButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  TextInput
} from 'react-admin';
import {
  FieldGuesser
} from "@api-platform/admin";
import { PostBulkActionButtons } from "../ActionButtons";

const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
    <TextInput source="id" />
    <TextInput source="description" />
    <TextInput source="email" />
    <TextInput source="createdAt" />
    <TextInput source="updatedAt" />
  </Filter>
);

const EstablishmentList = props => (
  <List  {...props} filters={<PostFilter />} sort={{ field: 'name', order: 'ASC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={25} >
    <Datagrid>
      <FieldGuesser source="establishmentId" label="id" />
      <FieldGuesser source="name" />
      <FieldGuesser source="description" />
      <FieldGuesser source="email" />
      <FieldGuesser source="createdAt" />
      <FieldGuesser source="updatedAt" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteWithConfirmButton confirmTitle='ra.action.delete' confirmContent='ra.message.delete_content' label="" />
    </Datagrid>
  </List>
);

export default EstablishmentList;
