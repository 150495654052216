import React, { Fragment } from "react";
import {
  ReferenceField,
  TextField,
  Filter,
  SearchInput,
  ReferenceArrayInput,
  SelectArrayInput,
  DeleteWithConfirmButton,
  ShowButton,
  Datagrid,
  EditButton,
  List,
  TextInput
} from 'react-admin';
import {
  FieldGuesser
} from "@api-platform/admin";

import { PostBulkActionButtons } from "../ActionButtons";
const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
    <TextInput source="establishment.name" label="resources.establishments.name" />
    <TextInput source="subjects.name" label="resources.subjects.name" />
    <TextInput source="nbLearningGoals" />
    <TextInput source="nbLearningObjectives" />
    <TextInput source="updatedAt" />
    <TextInput source="createdAt" />
  </Filter>
);

const ProgramList = props => (
  <List {...props} filters={<PostFilter />} sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={25}>
    <Datagrid >
      <FieldGuesser source="name" />
      <ReferenceField source="subject" reference="subjects" sortBy="subject.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name">
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source="nbLearningGoals" />
      <FieldGuesser source="nbLearningObjectives" />
      <FieldGuesser source="createdAt" />
      <FieldGuesser source="updatedAt" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteWithConfirmButton confirmTitle='ra.action.delete' confirmContent='ra.message.delete_content' label="" />
    </Datagrid >
  </List >
);

export default ProgramList;
