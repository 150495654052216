import React from "react";
import {
  resolveBrowserLocale,
  defaultTheme,
  fetchUtils,
  Admin,
  Resource
} from "react-admin";
import { createMuiTheme } from '@material-ui/core/styles';
import {
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";
import keycloakAuthProvider from "./keycloakAuthProvider";
import {
  HydraAdmin,
  ResourceGuesser,
} from "@api-platform/admin";
import {
  AcademicYearList,
  AcademicYearShow,
  AcademicYearCreate,
  AcademicYearEdit,
} from "./academicYear";
import {
  UserList,
  UserShow,
  UserCreate,
  UserEdit
} from "./user";

import {
  SchoolClassList,
  SchoolClassShow,
  SchoolClassCreate,
  SchoolClassEdit,
} from "./schoolClass";

import {
  SubjectList,
  SubjectShow,
  SubjectCreate,
  SubjectEdit,
} from "./subject";

import {
  ProgramList,
  ProgramShow,
  ProgramCreate,
  ProgramEdit,
} from "./program";

import {
  EstablishmentList,
  EstablishmentShow,
  EstablishmentCreate,
  EstablishmentEdit,
} from "./establishment";

import {
  ExamList,
  ExamShow,
  ExamEdit,
} from "./exam";

import {
  AssessmentTypeList,
  AssessmentTypeShow,
  AssessmentTypeCreate,
  AssessmentTypeEdit,
} from "./assessmentType";

import {
  LmsConfigurationList,
  LmsConfigurationShow,
  LmsConfigurationCreate,
  LmsConfigurationEdit,
} from "./lmsConfiguration";

import {
  UserDetailList,
  UserDetailShow,
  UserDetailCreate,
  UserDetailEdit,
} from "./userDetail";

import {
  OnboardingCustomTextEdit,
  OnboardingCustomTextList,
  OnboardingCustomTextShow,
  OnboardingCustomTextCreate,
} from "./onboardingCustomText";

import Home from "@material-ui/icons/Home";
import People from "@material-ui/icons/People";
import School from "@material-ui/icons/School";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Event from "@material-ui/icons/Event";
import Palette from "@material-ui/icons/Palette";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { fri18nMessages, eni18nMessages } from "./i18n";
import simpleRestProvider from "ra-data-simple-rest";
import Dashboard from "./Dashboard";
import { API_ENTRYPOINT } from "./AppConfig"
import axios from 'axios'
import MyLayout  from "./MyLayout";
import { keycloak } from "./keycloakAuthProvider";


const messages = {
  fr: fri18nMessages,
  en: eni18nMessages,
};

const i18nProvider = polyglotI18nProvider(
  (locale) => (messages[locale] ? messages[locale] : messages.fr),
  resolveBrowserLocale()
);

// url of the api
const entrypoint = API_ENTRYPOINT;

// Dataprovider for HydraAdmin
const fetchHeaders = {
  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
};

const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
  });

const apiDocumentationParser = (entrypoint) =>
  parseHydraDocumentation(entrypoint, {
    headers: new Headers(fetchHeaders),
  }).then(
    ({ api }) => ({ api }),
    (result) => {
      if (result.status === 401) {
        return Promise.resolve({
          api: result.api,
          customRoutes: [
            <Route
              path="/"
              render={() => {
                return (
                  window.localStorage.removeItem("token"),
                  <Redirect to="/login" />
                );
              }}
            />,
          ],
        });
      }
      else {
        return Promise.reject(result);
      }
    }
  );

const apiDataProvider = baseHydraDataProvider(
  entrypoint,
  fetchHydra,
  apiDocumentationParser
);

// data provider for react-admin
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  options.headers.set(
    "Authorization",
    `Bearer ${window.localStorage.getItem("token")}`
  );
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = simpleRestProvider(entrypoint, httpClient);

const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    width: 300, // The default value is 240
    closedWidth: 70, // The default value is 55
    secondary: {
      main: "#1D39C4", // Not far from orange
    },
  }
});

const App = () => {
  if (!localStorage.getItem("token")) {
    return (
      <Admin layout={MyLayout}
        theme={theme}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={keycloakAuthProvider}
      >
        <Resource name="users" list={UserList} />
      </Admin>
    );
  }
  else {

    var data = axios.get(entrypoint + `/users/me`).
      then(res => {
        if (!(res.data.roles.indexOf("ROLE_SUPER_ADMIN") > -1)) {
          return (
            keycloak.init()
              .then(function (authenticated) {
                localStorage.removeItem("loginInProgress");
                localStorage.removeItem("token");
                keycloak.logout();
              })
              .catch(function () {
                console.log('failed to initialize')
              })
          )
        }
      })
      console.log(localStorage.getItem('locale_language'))
    return (
      <HydraAdmin layout={MyLayout}
        theme={theme}
        dataProvider={apiDataProvider}
        authProvider={keycloakAuthProvider}
        entrypoint={entrypoint}
        locale="fr"
        i18nProvider={i18nProvider}
      >
        <ResourceGuesser
          name="establishments"
          list={EstablishmentList}
          show={EstablishmentShow}
          create={EstablishmentCreate}
          edit={EstablishmentEdit}
          icon={Home}
        />
        <ResourceGuesser
          name="academic-years"
          list={AcademicYearList}
          show={AcademicYearShow}
          create={AcademicYearCreate}
          edit={AcademicYearEdit}
          icon={School}
        />
        <ResourceGuesser
          name="subjects"
          list={SubjectList}
          show={SubjectShow}
          create={SubjectCreate}
          edit={SubjectEdit}
          icon={Palette}
        />
        <ResourceGuesser
          name="school-classes"
          list={SchoolClassList}
          show={SchoolClassShow}
          create={SchoolClassCreate}
          edit={SchoolClassEdit}
          icon={LocalLibrary}
        />
        <ResourceGuesser
          name="programs"
          list={ProgramList}
          show={ProgramShow}
          create={ProgramCreate}
          edit={ProgramEdit}
          icon={LibraryBooks}
        />
        <ResourceGuesser
          name="users"
          list={UserList}
          show={UserShow}
          create={UserCreate}
          edit={UserEdit}
          icon={People}
        />
        <ResourceGuesser
          name="exams"
          list={ExamList}
          show={ExamShow}
          edit={ExamEdit}
          icon={Event}
        />
        <ResourceGuesser
          name="assessment-types"
          list={AssessmentTypeList}
          show={AssessmentTypeShow}
          create={AssessmentTypeCreate}
          edit={AssessmentTypeEdit}
        />
        <ResourceGuesser
          name="onboarding-custom-texts"
          list={OnboardingCustomTextList}
          show={OnboardingCustomTextShow}
          create={OnboardingCustomTextCreate}
          edit={OnboardingCustomTextEdit}
        />
        <ResourceGuesser
          name="lms-configurations"
          list={LmsConfigurationList}
          show={LmsConfigurationShow}
          create={LmsConfigurationCreate}
          edit={LmsConfigurationEdit}
        />
        <Resource
          name="exam-params"
        />
        <Resource
          name="establishment-ftp-params"
        />
        <Resource
          name="user-details"
          show={UserDetailShow}
          create={UserDetailCreate}
          edit={UserDetailEdit}
        />
      </HydraAdmin>
    );
  }
}

export default App;
