import React from "react";
import {
  DeleteWithConfirmButton,
  SearchInput,
  ShowButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  BooleanInput,
  BooleanField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayInput,
  DateInput,
  ReferenceField,
  TextField,
  TextInput
} from 'react-admin';
import {
  FieldGuesser,
  ShowGuesser
} from "@api-platform/admin";
import { PostBulkActionButtons } from "../ActionButtons";

const AcademicYearExpand = props => (
  <ShowGuesser {...props} actions={false}>
    <ReferenceArrayField reference="school-classes" source="classes" sortable={false}>
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ShowGuesser>

)

const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
    <TextInput source="establishment.name" label="resources.establishments.name" />
    <TextInput source="startDate" />
    <TextInput source="endDate" />
    <BooleanInput source="current" />
    <TextInput source="createAt" />
    <TextInput source="updatedAt" />

  </Filter>
);

const AcademicYearList = props => (
  <List {...props} filters={<PostFilter />} sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={25} >
    <Datagrid expand={<AcademicYearExpand />}>
      <FieldGuesser source="name" />
      <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name">
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source="nbClasses" />
      <FieldGuesser source="startDate" />
      <FieldGuesser source="endDate" />
      <BooleanField source="current" />
      <FieldGuesser source="createdAt" />
      <FieldGuesser source="updatedAt" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteWithConfirmButton confirmTitle='ra.action.delete' confirmContent='ra.message.delete_content' label="" />
    </Datagrid>
  </List>
);

export default AcademicYearList;
