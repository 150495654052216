import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import Keycloak from 'keycloak-js';
import { KEYCLOAK_URL, KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM } from './AppConfig'

var kcConfig = {
    "url": KEYCLOAK_URL,
    "clientId": KEYCLOAK_CLIENT_ID,
    "realm": KEYCLOAK_REALM
}
export const keycloak = new Keycloak(kcConfig);

export default (type, params) => {
    if (type === AUTH_LOGOUT) {
        keycloak.init().then(function (authenticated) {
            localStorage.removeItem("loginInProgress");
            localStorage.removeItem("token");
            keycloak.logout();
        }).catch(function () {
            console.log('failed to initialize');
        });
    }

    if (type === AUTH_CHECK) {
        var token = localStorage.getItem("token");
        if (!!token) {
            return Promise.resolve();
        } else {
            var loginInProgress = localStorage.getItem("loginInProgress");
            if (!!loginInProgress) {
                keycloak.init().then(function (authenticated) {
                    if (authenticated) {
                        localStorage.removeItem("loginInProgress");
                        localStorage.setItem("token", keycloak.token);
                        window.location.reload();
                    }
                }).catch(function () {
                    console.log('failed to initialize');
                });
            } else {
                localStorage.setItem("loginInProgress", true);
                keycloak.init({
                    onLoad: 'login-required'
                }).catch(function () {
                    console.log('failed to initialize');
                });
            }
        }
    }

    return Promise.resolve();
};
