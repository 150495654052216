import React, { Fragment } from "react";
import {
  ReferenceField,
  TextField,
  DeleteWithConfirmButton,
  ShowButton,
  Datagrid,
  EditButton,
  List,
  TextInput,
  BooleanInput,
  SearchInput,
  Filter
} from 'react-admin';
import {
  FieldGuesser
} from "@api-platform/admin";
import { PostBulkActionButtons } from "../ActionButtons";

const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
    <TextInput source="id" />
    <TextInput source="establishment.name" label="resources.establishments.name" />
    <BooleanInput source="isDefault" />
    <BooleanInput source="doubleApproval" />
    <TextInput source="createAt" />
    <TextInput source="updatedAt" />
  </Filter>
);

const AssessmentTypeList = props => (
  <List {...props} filters={<PostFilter />}sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={25}>
    <Datagrid>
      <FieldGuesser source="assessmentTypeId" />
      <FieldGuesser source="name" />
      <FieldGuesser source="doubleApproval" />
      <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name">
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source="isDefault" />
      <FieldGuesser source="createdAt" />
      <FieldGuesser source="updatedAt" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteWithConfirmButton confirmTitle='ra.action.delete' confirmContent='ra.message.delete_content' label="" />
    </Datagrid >
  </List >
);

export default AssessmentTypeList;
